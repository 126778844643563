import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from '../../environments/environment';

@Injectable()
export class authInterceptor implements HttpInterceptor {
  tokenSubject: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  isRefreshingToken = false;

  constructor(private router: Router, private auth: AngularFireAuth) {}
  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    if (this.isInBlockedList(req.url)) {
      return next.handle(req);
    } else {
      return this.auth.idToken.pipe(
        take(1),
        switchMap((token: any) => {
          if (token) {
            req = req.clone({
              setHeaders: { Authorization: `Bearer ${token}` },
            });
          }
          return next.handle(req);
        })
      );
    }
  }
  private isInBlockedList(url: string) {
    if (!url.includes(`${environment.backendUrl}`)) {
      return true;
    } else {
      return false;
    }
  }
}
