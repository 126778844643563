// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  firebase: {
    projectId: 'zing-dev-cinnova',
    appId: '1:1094174264748:web:267e27190e71581310be09',
    storageBucket: 'zing-dev-cinnova.appspot.com',
    apiKey: 'AIzaSyCzFy6JZnI09e-sPo_qu2WnpaC_XOkxLUw',
    authDomain: 'zing-dev-cinnova.firebaseapp.com',
    messagingSenderId: '1094174264748',
  },
  backendUrl: 'https://zing-dev.azure-api.net/',
   //backendUrl: 'http://localhost:7071/api/',
  
  sasToken:
    'sp=racw&st=2022-08-09T09:46:05Z&se=2025-01-01T17:46:05Z&spr=https&sv=2021-06-08&sr=c&sig=jdaOUw3HUGopyV53OXr3sxbHppBTGTgmRaePccxw4Tg%3D',
  sasTokenInvoice:
    'sp=racw&st=2022-09-28T07:24:53Z&se=2026-01-01T15:24:53Z&spr=https&sv=2021-06-08&sr=c&sig=vAKJNfAZZ1rHJCGR1N8LTBI8tytZ86QzFhJk9evuP3E%3D',
  containerName: 'batch-accounts',
  invoiceContainerName: 'invoices',
  storageAccountNAame: 'batchupload',
  production: false,
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
