import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AdminGuard } from './Guard/roleGuard/admin.guard';
import { pipe } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  redirectUnauthorizedTo,
  redirectLoggedInTo,
  customClaims,
  AuthGuard,
} from '@angular/fire/auth-guard';
import {
  AngularFireAuthGuard,
  canActivate,
} from '@angular/fire/compat/auth-guard';
import { AuthService } from './services/auth/auth.service';

const userOnly = () =>
  pipe(
    customClaims,
    map((claims) => claims.role === 'user')
  );
const adminOnly = () =>
  pipe(
    customClaims,
    map((claims) => claims.role === 'admin')
  );

let _authService: AuthService;

const redirectUnauthorizedToLogin = () =>
  redirectUnauthorizedTo(['auth/login']);

const user = JSON.parse(localStorage.getItem('User_db'));
const redirectLoggedInUser = () => {
  return user?.role === 'admin'
    ? redirectLoggedInTo(['/admin/dashboard'])
    : redirectLoggedInTo(['/user/dashboard/home']);
};

const routes: Routes = [
  {
    path: '',
    redirectTo: 'auth/login',
    pathMatch: 'full',
  },
  {
    path: 'auth',
    loadChildren: () =>
      import('./views/auth/auth.module').then((m) => m.AuthPageModule),
    canActivate: [AngularFireAuthGuard],
    data: { authGuardPipe: redirectLoggedInUser },
  },
  {
    path: 'admin',
    loadChildren: () =>
      import('./views/admin/admin.module').then((m) => m.AdminPageModule),
    ...canActivate(adminOnly),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./views/user/user.module').then((m) => m.UserPageModule),
    ...canActivate(userOnly),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
