import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { pageView, pages, adminPages, userPages } from './menu.pages';
import { AuthService } from '../auth/auth.service';
import { Auth } from '@angular/fire/auth';
import { get_user } from '../../views/generic-model/user.model';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private pages: BehaviorSubject<pageView[]> = new BehaviorSubject<pageView[]>(
    []
  );

  private profileData: BehaviorSubject<get_user> =
    new BehaviorSubject<get_user>(null);

  constructor(
    private menu: MenuController,
    private router: Router,
    private _auth: AuthService,
    private fAuth: Auth
  ) {
    this.initPages();
  }
  user = JSON.parse(localStorage.getItem('User_db'));
  public showMenu() {
    this.menu.enable(true);
  }

  public hideMenu() {
    this.menu.enable(false);
  }

  public collapseMenu() {
    if (this.menu.isOpen()) {
      this.menu.close();
    }
  }
  public async getProfile() {
    try {
      const response = await this._auth.getUserData();
      this.profileData.next(response['data']);
      return response
    } catch (error) {
      
      console.warn(error);
    }
  }
  public initPages() {
    const user = JSON.parse(localStorage.getItem('User_db'));
    let p;

    if (user === null) {
      p = pages;
      this.pages.next(p);
      this.checkCurrentRoute();
    } else if (user['role'] === 'user') {
      p = userPages;
      this.pages.next(p);
      this.checkCurrentRoute();
    } else if (user['role'] === 'admin') {
      p = adminPages;
      this.pages.next(p);
      this.checkCurrentRoute();
    }
  }

  public changePage(url: string) {
    this.collapseMenu();
    this.router.navigate([url], {
      replaceUrl: true,
      skipLocationChange: false,
    });
  }

  public collapseMenuItem(id: string) {
    let pages: pageView[] = [];
    this.pages.pipe(take(1)).subscribe((_pages) => {
      pages = _pages;
    });

    let p = pages.map((e) => e.id).indexOf(id);
    pages[p].collapsed = !pages[p].collapsed;

    this.pages.next(pages);
  }

  public checkCurrentRoute() {
    let pages: pageView[] = [];
    let selected_parent_index, selected_child_index;
    let to_parent_index, to_child_index;

    this.pages.pipe(take(1)).subscribe((_pages) => {
      pages = _pages;
    });
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event) => {
        pages.forEach((sp, spi) => {
          sp.pages.forEach((pp, pi) => {
            if (pp.selected) {
              selected_parent_index = spi;
              selected_child_index = pi;
            }
            if (pp.url === event['url']) {
              to_parent_index = spi;
              to_child_index = pi;
            }
          });

          if (sp.selected) {
            selected_parent_index = spi;
          }
          if (sp.url === event['url']) {
            to_parent_index = spi;
          }
        });

        //reset selected page
        if (
          selected_parent_index !== null &&
          selected_parent_index !== undefined
        ) {
          if (
            selected_child_index !== null &&
            selected_child_index !== undefined
          ) {
            pages[selected_parent_index].pages[selected_child_index].selected =
              false;
            pages[selected_parent_index].collapsed = true;
          } else {
            pages[selected_parent_index].selected = false;
          }
        }

        //select new page
        if (to_parent_index !== null && to_parent_index !== undefined) {
          if (to_child_index !== null && to_child_index !== undefined) {
            pages[to_parent_index].pages[to_child_index].selected = true;
            pages[to_parent_index].collapsed = false;
          } else {
            pages[to_parent_index].selected = true;
          }
        }

        this.pages.next(pages);
      });
  }

  public get pages$(): Observable<pageView[]> {
    return this.pages.asObservable();
  }

  public get getProfileData$(): Observable<get_user> {
    return this.profileData.asObservable();
  }
}
