import { Injectable } from '@angular/core';

import {
  Auth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  sendPasswordResetEmail,
} from '@angular/fire/auth';
import { ApiService } from '../api/api.service';
import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { AuthState, Login } from './auth.model';
import { create_user } from './auth.model';
import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { generate, verify } from '../../views/generic-model/otp.model';
import { ToastService } from '../toast/toast.service';
import { ModalController } from '@ionic/angular';
import { HttpParams } from '@angular/common/http';
import { AngularFireAuth } from '@angular/fire/compat/auth';

const initialAuthState = {
  isLoggedIn: false,
  isEmailVerified: false,
  id: null,
  email: null,
  name: null,
  phone_number: null,
  token: null,
  display_picture: null,
};

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private isLoading = new BehaviorSubject<boolean>(false);
  private readonly _authState = new BehaviorSubject<AuthState>(
    initialAuthState
  );
  private isTwoFaStatus = new BehaviorSubject<boolean>(null);
  createUserPayload: create_user;
  phone_number_local: string;
  local_email: string;
  local_pass: string;

  private user: any;

  /** AuthState as an Observable */
  readonly auth$ = this._authState.asObservable();
  private isLoginLoading = new BehaviorSubject<boolean>(false);
  private isRegisterLoading = new BehaviorSubject<boolean>(false);
  private isOTPloading = new BehaviorSubject<boolean>(false);
  private isLoginReportLoading = new BehaviorSubject<boolean>(false);
  private isPasswordResetEmailLoading = new BehaviorSubject<boolean>(false);
    currentUser: any;

  constructor(
    private auth: Auth,
    private router: Router,
    private _apiService: ApiService,
    private _toast: ToastService,
    private modalHandler: ModalController,
    private angularFireAuth:AngularFireAuth
  ) {
    this.auth.onAuthStateChanged((user) => {
      if (!user) {
        this.isLoading.next(false);
        this._authState.next(initialAuthState);
      } else {
        this.user = user;
        user.getIdToken(true).then((token) => {
          this.isLoading.next(false);

          this._authState.next({
            isEmailVerified: user.emailVerified,
            id: user.uid,
            email: user.email,
            name: user.displayName,
            phone_number: user.phoneNumber,
            display_picture: user.photoURL,
            token: token,
          });
        });
      }
    });
  }

  // Send new sign up email notification to admins
  async sendSignUpEmailNotificationToAdmins(newSignUpName: string, newSignUpEmail: string) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('type', 'email_notification');
    let bodyPayload = {
      newSignupName: newSignUpName,
      newSignupEmail: newSignUpEmail
    };
    return await this._apiService
      .post('email-cycle', bodyPayload, queryParams)
      .toPromise();
  }

  //#region : logic for user role identification

  public respectiveRole() {
    this.user.getIdTokenResult().then((token) => {
      if (token.claims.role === 'admin') {
        return this.router.navigate(['/admin/dashboard']);
      } else {
        return this.router.navigate(['/user/dashboard/home']);
      }
    });
  }

  //#endregion

  //#region : Login in with the respective role

  //OTP check is still required to be added.

  async login({ email, password }: Login) {
    try {
      //#region : Firebase login
      this.isLoginLoading.next(true);
      this.local_email = email;
      this.local_pass = password;
      //now add previous error handling here
      const res = await signInWithEmailAndPassword(this.auth, email, password);
      let token = (await res.user.getIdTokenResult()).token;
      let queryParms = new HttpParams();
      queryParms = queryParms.append('type', 'getUser');
      queryParms = queryParms.append('firebaseId', res.user.uid);
      const localResponse = await this._apiService
        .get('auth', queryParms)
        .toPromise();
      localStorage.setItem('User_db', JSON.stringify(localResponse['data']));
      this.phone_number_local = localResponse['data']['phone_number'];
      //#endregion

      //#region : OTP Handling if chosen by the user
      if (localResponse['data']['multi_factor'] === true) {
        localStorage.removeItem('User_db');
        this.logout();
        const res = await this.generateOTP(
          localResponse['data']['phone_number']
        );
        if (res['status'] === 200) {
          this._toast.presentToastWithOptions(`${res['message']}`, 'success');
          this.isTwoFaStatus.next(true);
          this.isLoginLoading.next(false);
          await this.logUserLoginTimestamp();
        } else {
          this._toast.presentToastWithOptions(`${res['message']}`, 'danger');
          this.isTwoFaStatus.next(false);
          this.isLoginLoading.next(false);
        }
      } else {
        this.isTwoFaStatus.next(false);
        this.isLoginLoading.next(false);
        this.respectiveRole();
        await this.logUserLoginTimestamp();
      }
      //#endregion

      //Respectiv Role Login
    } catch (error) {
      if (error.message.includes('wrong-password')) {
        this._toast.presentToastWithOptions(
          'Wrong password entered.',
          'danger'
        );
        this.isTwoFaStatus.next(false);
      }
      if (error.message.includes('user-not-found')) {
        this._toast.presentToastWithOptions('User not found.', 'danger');
        this.isTwoFaStatus.next(false);
      }
      if (error.message.includes('user-disabled')) {
        this._toast.presentToastWithOptions(
          'Pending admin approval.',
          'warning'
        );
        this.isTwoFaStatus.next(false);
      }
    } finally {
      this.isLoginLoading.next(false);
    }
  }

  //#endregion

  //#region : Registeration Logic for the user
  async register(
    email: string,
    password: string,
    name: string,
    phone_number: string,
    addLineOne: string,
    country: string,
    city: string,
    stateOrProvince: string,
    addLineTwo: string,
    company_name: string,
    company_number: string,
    login_email: string,

  ) {
    try {
      this.isRegisterLoading.next(true);
      const res = await createUserWithEmailAndPassword(
        this.auth,
        login_email,
        password
      );

      this.createUserPayload = {
        id: res.user.uid,
        name: name,
        email: email,
        phone_number: phone_number,
        addLineOne: addLineOne,
        addLineTwo: addLineTwo,
        country: country,
        city: city,
        stateOrProvince: stateOrProvince,
        company_name: company_name,
        company_number: company_number,
        login_email:login_email
      };
      let queryParms = new HttpParams();
      queryParms = queryParms.append('type', 'user');
      let dbRes = await this._apiService
        .post('auth', this.createUserPayload, queryParms)
        .toPromise();
      this.sendSignUpEmailNotificationToAdmins(name, login_email);
      this._toast.presentPermanentToastWithOptions(
        'You can log in to the portal once account get approved by the admin',
        'success'
      );
      this.logout();

      this.isRegisterLoading.next(false);
    } catch (error) {
      if (error.message.includes('email-already-in-use')) {
        this._toast.presentToastWithOptions(
          'This email is already in use.',
          'danger'
        );
      } else if (error.error?.code === 'auth/phone-number-already-exists') {
        this._toast.presentToastWithOptions(error.error?.message, 'danger');
      }
      this.isRegisterLoading.next(false);
    }
  }

  //#endregion

  //#region : Logout the user
  public async logout() {
    await signOut(this.auth);
    localStorage.clear();
    this.router.navigate(['auth/login'], { replaceUrl: true });
  }

  //#endregion

  async getUserData() {
    let firebaseID = this.auth.currentUser.uid;
    let queryParms = new HttpParams();
    queryParms = queryParms.append('type', 'getUser');
    queryParms = queryParms.append('firebaseId', firebaseID);
    return await this._apiService.get('auth', queryParms).toPromise();
  }

  //#region  Forgot Password Logic
  public forgotPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

  //#endregion

  //#region Create Admin

  // Create New Admin
  async createNewAdmin(
    name: string,
    email: string,
    phone_number: string,
  ) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('type', 'admin');
    let bodyPayload = {
      name: name,
      email: email,
      phone_number: phone_number
    }
    return await this._apiService
      .post('auth', bodyPayload, queryParams)
      .toPromise();
  }

  //#endregion Create Admin

  //#region : OTP Generate Logic
  async generateOTP(p_number: string) {
    try {
      this.isLoading.next(true);
      let otpPayLoad: generate = {
        phone_number: p_number,
      };
      let queryParms = new HttpParams();
      queryParms = queryParms.append('type', 'generate');

      return await this._apiService
        .post(`otp`, otpPayLoad, queryParms)
        .toPromise();
    } catch (error) {
      this._toast.presentToastWithOptions('OTP service error', 'warning');
      this.isLoading.next(false);
    } finally {
      this.isLoading.next(false);
    }
  }

  //#endregion

  async verifyOTPandLogin(verification_code: string) {
    let otpVerifyLoad: verify = {
      phone_number: this.phone_number_local,
      code: verification_code,
    };
    this.isOTPloading.next(true);
    let queryParms = new HttpParams();
    queryParms = queryParms.append('type', 'verify');
    try {
      let response = await this._apiService
        .post(`otp`, otpVerifyLoad, queryParms)
        .toPromise();

      //OTP VERIFY Success
      if (response['status'] === 200) {
        this._toast.presentToastWithOptions(
          `${response['message']}`,
          'success'
        );
        const res = await signInWithEmailAndPassword(
          this.auth,
          this.local_email,
          this.local_pass
        );
        let token = (await res.user.getIdTokenResult()).token;
        let queryParms = new HttpParams();
        queryParms = queryParms.append('type', 'getUser');
        queryParms = queryParms.append('firebaseId', res.user.uid);
        const localResponse = await this._apiService
          .get('auth', queryParms)
          .toPromise();
        localStorage.setItem('User_db', JSON.stringify(localResponse['data']));
        this.isLoginLoading.next(false);
        this.isOTPloading.next(false);
        this.isTwoFaStatus.next(false);
        this.modalHandler.dismiss();
        this.respectiveRole();
        //To save the user's login time
        await this.logUserLoginTimestamp();
      }
    } catch (error) {
      if (error['error']['status'] === 403) {
        this.isTwoFaStatus.next(true);
        this._toast.presentToastWithOptions(
          `${error['error']['message']}`,
          'danger'
        );
        this.isLoginLoading.next(false);
        this.isTwoFaStatus.next(true);
      }
      this.isOTPloading.next(false);
    } finally {
      this.isOTPloading.next(false);
    }
  }

  async logUserLoginTimestamp() {
    this.isLoginReportLoading.next(true)
    try {
      let firebaseID = this.auth.currentUser.uid;
      let queryParms = new HttpParams();
      queryParms = queryParms.append('type', 'logUserLoginTimestamp');
      queryParms = queryParms.append('firebaseId', firebaseID);
      const localResponse = await this._apiService
        .get('auth', queryParms)
        .toPromise();
      this.isLoginReportLoading.next(false);

    } catch (error) {
      if (error['error']['status'] === 403) {
        this.isTwoFaStatus.next(true);
        this._toast.presentToastWithOptions(
          `${error['error']['message']}`,
          'danger'
        );
        this.isLoginReportLoading.next(false);
      }
    }
  }

  async getUserLoginTimestamp(limit, page, firebaseID) {
    this.isLoginReportLoading.next(true)
    try {
      let queryParms = new HttpParams();
      queryParms = queryParms.append('type', 'getUserLoginTimestamp');
      queryParms = queryParms.append('firebaseId', firebaseID);
      queryParms = queryParms.append('limit', limit);
      queryParms = queryParms.append('page', page);
      const localResponse = await this._apiService
        .get('auth', queryParms)
        .toPromise();
      this.isLoginReportLoading.next(false);

      return localResponse;

    } catch (error) {
      if (error['error']['status'] === 403) {
        this._toast.presentToastWithOptions(
          `${error['error']['message']}`,
          'danger'
        );
        this.isLoginReportLoading.next(false);
      }
    }
  }

  //#region Reset Password Email Generation
  async passwordResetEmailGenerate() {
    this.isPasswordResetEmailLoading.next(true);
    sendPasswordResetEmail(this.auth, this.auth.currentUser.email)
      .then(() => {
        // Password reset email sent!
        this.isPasswordResetEmailLoading.next(false);
        this._toast.presentToastWithOptions(
          'A reset-password email was sent to your email address.',
          'success'
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        this._toast.presentToastWithOptions(
          'This service is unavailable at the moment.',
          'danger'
        );
        this.isPasswordResetEmailLoading.next(false);
        // ..
      });
  }

  async resetPasswordOnLoginPage(email) {
    this.isPasswordResetEmailLoading.next(true);
    this.angularFireAuth.sendPasswordResetEmail(email)
      .then(() => {
        // Password reset email sent!
        this.isPasswordResetEmailLoading.next(false);
        this._toast.presentToastWithOptions(
          'A reset-password email was sent to your email address.',
          'success'
        );
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        this._toast.presentToastWithOptions(
          'This service is unavailable at the moment.',
          'danger'
        );
        this.isPasswordResetEmailLoading.next(false);
        // ..
      });
  }
  //#endregion

  public changeLoaderState(state: boolean) {
    this.isLoading.next(state);
  }

  public get isTwoFaStatus$(): Observable<boolean> {
    return this.isTwoFaStatus.asObservable();
  }

  public matchPasswordsValidator: ValidatorFn = (
    control: AbstractControl
  ): ValidationErrors | null => {
    const password = control.get('password');
    const confirmPassword = control.get('confirmPassword');

    return password.value !== confirmPassword.value
      ? { mismatchedPasswords: true }
      : null;
  };

  async editUserSettings(
    settingName: string,
    settingEdit: string,
    userId: string
  ) {
    let queryParams = new HttpParams();
    queryParams = queryParams.append('type', 'updateUser');
    queryParams = queryParams.append('edit_info', settingName);
    
    let bodyPayload = {};
    bodyPayload[settingName] = settingEdit;
    bodyPayload['id'] = userId;
    
    return await this._apiService
      .post('auth', bodyPayload, queryParams)
      .toPromise();
  }

  public get isLoginLoading$(): Observable<boolean> {
    return this.isLoginLoading.asObservable();
  }

  public get isLoginReportLoading$(): Observable<boolean> {
    return this.isLoginReportLoading.asObservable();
  }

  public get isRegisterLoading$(): Observable<boolean> {
    return this.isRegisterLoading.asObservable();
  }

  public get isOTPloading$(): Observable<boolean> {
    return this.isOTPloading.asObservable();
  }

  public get isPasswordResetEmailLoading$(): Observable<boolean> {
    return this.isPasswordResetEmailLoading.asObservable();
  }
}
