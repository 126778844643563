import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  private baseURL: string = environment.backendUrl;

  constructor(private http: HttpClient) {}

  get(url: string, params: HttpParams): Observable<any> {
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http.get(this.baseURL + url, {
      headers: new HttpHeaders(headers),
      params: params,
    });
  }

  post(url: string, body: Object, params: HttpParams): Observable<any> {
    let queryParms = new HttpParams();
    const headers = {
      'Content-Type': 'application/json',
    };
    return this.http.post(this.baseURL + url, body, {
      headers: new HttpHeaders(headers),
      params: params,
    });
  }
}
