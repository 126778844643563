import { Component, OnInit, OnDestroy } from '@angular/core';
import { BehaviorSubject, interval, Observable } from 'rxjs';
import { MenuService } from './services/menu/menu.service';
import { pageView, adminPages, userPages } from './services/menu/menu.pages';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { Auth } from '@angular/fire/auth';
import { AuthService } from './services/auth/auth.service';
import { get_user } from './views/generic-model/user.model';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  icon: string = '';

  pages$: Observable<pageView[]>;
  private pages: BehaviorSubject<pageView[]> = new BehaviorSubject<pageView[]>(
    []
  );
  profileData$: Observable<get_user>;
  subscription: any;
  currentRoute: any;
  profileList: boolean;
  profileData;

  constructor(
    private menu: MenuService,
    private router: Router,
    private _currRoute: ActivatedRoute,
    private _popover: PopoverController,
    private auth: AuthService,
    private fAuth: Auth
  ) {
    this.initMenu();
  }
  ngOnInit(): void {
    this.profileData$ = this.menu.getProfileData$;
    this.watchUserRole();
  }

  watchUserRole() {
    this.profileData$.subscribe((res) => {
      this.profileData = res;
    })
  }

  async logOut() {
    this.auth.logout();
  }
  navigateToLogin() {
    this.router.navigate(['/auth/login'], { replaceUrl: true });

    this._popover.dismiss();
  }
  navigateToSettings() {
    if(this.profileData?.role === 'admin') {
      this.router.navigate(['/admin/admin-settings'], { replaceUrl: true });
      this._popover.dismiss();
    }
    else {
      this.router.navigate(['/user/settings'], { replaceUrl: true });
      this._popover.dismiss();
    }
  }

  navigateToDomainVerification() {
    this.router.navigate(['/user/junk-settings'], { replaceUrl: true });
    this._popover.dismiss();
  }

  //#region Menu Management

  initMenu() {
    this.pages$ = this.menu.pages$;
  }

  navigateToPage(url: string) {
    this.menu.changePage(url);
  }

  collapseMenuItem(id: string) {
    this.menu.collapseMenuItem(id);
  }

  //#endregion Menu Management

  //#region Profile submenu handler

  profileMenuHandler() {
    if (this.profileList) {
      this.profileList = false;
    } else {
      this.profileList = true;
    }
  }

  //#endregion
}
