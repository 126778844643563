import { Injectable } from '@angular/core';
import { ToastController } from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(public toastController: ToastController) {}

  async presentToastWithOptions(message: string, color: string) {
    const toast = await this.toastController.create({
      duration: 3000,
      message: message,
      color: color,
      position: 'bottom',
      buttons: [
        {
          text: 'Done',
          role: 'cancel',
          handler: () => {
          },
        },
      ],
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
  }
  
  async presentPermanentToastWithOptions(message: string, color: string) {
    const toast = await this.toastController.create({
      message: message,
      color: color,
      position: 'bottom',
      buttons: [
        {
          text: 'Done',
          role: 'cancel',
          handler: () => {
          },
        },
      ],
    });
    await toast.present();

    const { role } = await toast.onDidDismiss();
  }
}
